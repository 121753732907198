var exports = {};

exports = function (hljs) {
  var smali_instr_low_prio = ["add", "and", "cmp", "cmpg", "cmpl", "const", "div", "double", "float", "goto", "if", "int", "long", "move", "mul", "neg", "new", "nop", "not", "or", "rem", "return", "shl", "shr", "sput", "sub", "throw", "ushr", "xor"];
  var smali_instr_high_prio = ["aget", "aput", "array", "check", "execute", "fill", "filled", "goto/16", "goto/32", "iget", "instance", "invoke", "iput", "monitor", "packed", "sget", "sparse"];
  var smali_keywords = ["transient", "constructor", "abstract", "final", "synthetic", "public", "private", "protected", "static", "bridge", "system"];
  return {
    aliases: ["smali"],
    contains: [{
      className: "string",
      begin: "\"",
      end: "\"",
      relevance: 0
    }, hljs.COMMENT("#", "$", {
      relevance: 0
    }), {
      className: "keyword",
      variants: [{
        begin: "\\s*\\.end\\s[a-zA-Z0-9]*"
      }, {
        begin: "^[ ]*\\.[a-zA-Z]*",
        relevance: 0
      }, {
        begin: "\\s:[a-zA-Z_0-9]*",
        relevance: 0
      }, {
        begin: "\\s(" + smali_keywords.join("|") + ")"
      }]
    }, {
      className: "built_in",
      variants: [{
        begin: "\\s(" + smali_instr_low_prio.join("|") + ")\\s"
      }, {
        begin: "\\s(" + smali_instr_low_prio.join("|") + ")((\\-|/)[a-zA-Z0-9]+)+\\s",
        relevance: 10
      }, {
        begin: "\\s(" + smali_instr_high_prio.join("|") + ")((\\-|/)[a-zA-Z0-9]+)*\\s",
        relevance: 10
      }]
    }, {
      className: "class",
      begin: "L[^(;:\n]*;",
      relevance: 0
    }, {
      begin: "[vp][0-9]+"
    }]
  };
};

export default exports;